import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, take, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../core';
import { UtilsService } from 'src/app/services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class CoreInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor( private oauthService: AuthService, private _router: Router,  private utilsService: UtilsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes("utils")) {
      return next.handle(request);
    }

    if (!request.url.includes(environment.authUrl)) {
      request = this.addLanguage(request, localStorage.getItem('language') || 'en');
      request = this.addCountry(request, 'en');
      request = this.addCurrency(request, 'en');
    }


    if (this.oauthService.hasValidToken() && (request.url.includes(environment.companyApi) || request.url.includes(environment.localizationApiUrl))) {
      let req: Observable<any> = this.checkUserInfo(request, next).pipe(switchMap((item: any) => {
        next.handle(item).pipe(catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(request, next);
          }
          else if (error instanceof HttpErrorResponse && error.status === 404) {
            return throwError(error);
          }
          else {
            return throwError(error);
          }
        }));
        return of(item);
      }));
      return req;

    }

    else {
      let req: Observable<any> = next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (error.url && error.url.includes(environment.userInfoUrl)) {
            this.oauthService.logout();
            this.oauthService.login();
            return of(null);
          } else {
            return this.handle401Error(request, next);
          }

        }
        else if (error instanceof HttpErrorResponse && error.status === 404) {
          return throwError(error);
        }
        else {
          return throwError(error);
        }
      }));
      return req;
    }


  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private addLanguage(request: HttpRequest<any>, language: string) {
    return request.clone({
      setHeaders: {
        'LanguageCode': language
      }
    });
  }
  private addCountry(request: HttpRequest<any>, countryCode: string) {
    return request.clone({
      setHeaders: {
        'CountryCode': countryCode
      }
    });
  }
  private addCurrency(request: HttpRequest<any>, currencyCode: string) {
    return request.clone({
      setHeaders: {
        'CurrencyCode': currencyCode
      }
    });
  }

  private checkUserInfo(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.oauthService.loadUserProfile()).pipe(
      switchMap((item) => {
        if (item) {
          request = this.addToken(request, this.oauthService.accessToken);
          //@ts-ignore
          (item?.info?.licenceexpiry) && this.checkthefuckingdate(item.info.licenceexpiry)
          return next.handle(request);
        }
        else if (item) {
          request = this.addToken(request, this.oauthService.accessToken);
          return next.handle(request);

        }
        return of(null);
      }), catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.oauthService.logout();
          this.oauthService.login()
          return of(null);
        } else {
          this.oauthService.logout();
          this.oauthService.login()
          return of(null);
        }
      }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.oauthService.silentRefresh()).pipe(
        switchMap(() => {
          if (this.oauthService.hasValidToken()) {
            return next.handle(this.addToken(request, this.oauthService.accessToken));
          }
          return of(null);
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        take(1),
        switchMap(() => {
          return next.handle(this.addToken(request, this.oauthService.accessToken
          ));
        }));
    }
  }

  private checkthefuckingdate(expiry: string) {
    this.utilsService.getServerTime().subscribe(serverTime => {
      if (new Date(expiry) <= new Date(serverTime)) {
        this.utilsService.updateLicenceWarning(false)
        this._router.navigate(['internal/expirypage'])
      } else {
        let threeDaysAfterNow = new Date(new Date().setDate(new Date(serverTime).getDate() + 3))
        this.utilsService.updateLicenceWarning(threeDaysAfterNow >= new Date(expiry));
      }
    })
  }

}
