import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    constructor(private _http:HttpClient){}
    public Save = (data: string | Blob, filename: string, extension: string) => FileSaver.saveAs(data, filename + extension);
    public saveFromURL=(url:string)=>this._http.get(url, { responseType: 'blob',   observe: 'response' })
}
