import { Injectable } from '@angular/core';
import { MathService } from './math.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

constructor(private _math: MathService) { }
//jms
calculateDiscountValue(item:any)//item could be attribute or product
{

  //check if has discount on item , done
  //get price without tax .. dibe
  //if percentage
  //{
  // calculate discount value
  // check that discount value is not greater than max value or set it to max
  // (productsubtotal* count ) - discount value
  //}
  //if value !percentage
  // handle it as the first part

  //returning should be as dtos for the product and discount
  //USE DISCOUNT PRICE FOR PRODUCT AND ATTRIBUTE PRICING
  let subTotalPrice:number = item.price.callcenter ? item.price.callcenter : item.price.default;
  let productPriceAfterDiscount:number = -1;
  if(item.discountinfo.length > 0){
      if(item.discountinfo[0].ispercentage){
        let discountvalue = subTotalPrice * (item.discountinfo[0].value / 100)
          productPriceAfterDiscount = (subTotalPrice * (item?.count || 1) ) - discountvalue;
      }else{
        productPriceAfterDiscount = (subTotalPrice) - item.discountinfo[0].value||0;
      }
      productPriceAfterDiscount < 0 ? productPriceAfterDiscount = 0 : productPriceAfterDiscount;
  }
  return item.productPriceAfterDiscount = this._math.round(productPriceAfterDiscount);
}

calculateDiscountValueFotItemAttDialog(item:any){
  let subTotalPrice:number = item.price.callcenter ? item.price.callcenter : item.price.default;
  let productPriceAfterDiscount:number = -1;
  if(item.discountinfo.length > 0){
      if(item.discountinfo[0].ispercentage){
        let discountvalue = subTotalPrice * (item.discountinfo[0].value / 100)
          productPriceAfterDiscount = (subTotalPrice * (item?.count || 1) ) - discountvalue;
      }else{
        productPriceAfterDiscount = (subTotalPrice) - item.discountinfo[0].value||0;
      }
      productPriceAfterDiscount < 0 ? productPriceAfterDiscount = 0 : productPriceAfterDiscount;
  }
  return item.productPriceAfterDiscount = this._math.round(productPriceAfterDiscount);
}

// //abuhussain
// validateCartDiscount(products:any[],discounts:Discounts[]){ //this one is for cart validation
//   //check the on hold discounts if any should be calculated
//   //check on active discounts if any should be removed
//   //you can reuse the first method to calc discount .
//   //you need to check on minprice minquantity , maxvalue
// }


//for any further questions call musa he is in charge for all of silly business validation @ 0798798906
}
