import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './shared/core';
import { CoreInterceptor, ErrorHandlerInterceptor } from './shared/helper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { NotLoggedInGuard } from './shared/core/authnotloggedinuser.guard';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
// declare let config: Config;
// //declare let configProduction: Config;
// let allowUrls: any = [/*environment.production === true ? configProduction.api : */config.api,
// /*environment.production === true ? configProduction.authUrl :*/ config.authUrl]
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    CoreModule.forRoot(/*environment.production === true ? configProduction.authConfig : */environment.authConfig),
    BrowserAnimationsModule,
    BlockUIModule.forRoot(),
    BlockUIHttpModule.forRoot({
      requestFilters: [
        /auth.ishbek.com/,
        /auth.ishbek.com/,
        /prodauth.ishbek.com/,
        /prodauth.ishbek.com/,
        /api\/branch\//,
        /api\/Order\/getOrderByBranchesId/,
        /api\/Order\/getScheduledOrders/,
        /api\/category\//,
        /api\/city\//, 
        /api\/Distance\/GetDeliveryPricing/,
        /api\/utils/,

      ]
      //links that you DONT want to block ui appears in (LOADING)
      /*
        /api\/Reports\//,
        /api\/Customer/,
        /api\/order\//,
        /api\/Order\//,
       */
    })
  ],
  providers: [
    DatePipe,
    NotLoggedInGuard,
    // {
    //   provide: APPCONFIG_TOKEN,
    //   useValue: /*environment.production === true ? configProduction : */config,
    // },
    { provide: HTTP_INTERCEPTORS, useClass: CoreInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
