
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MathService {
  constructor() { }
  round = (value: number) => +(Math.round(value * 10000) / 10000)
  ceil = (value: number) => +(Math.ceil(value * 10000) / 10000)
  roundandfix = (number: number) => +(Math.round(number * 10000) / 10000).toFixed(3);


}
