

import { InjectionToken } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
export let APPCONFIG_TOKEN = new InjectionToken<Config>('Configration');
export interface Config {
  production: boolean;
  api: string;
  defultLanugage: string;
  authConfig: AuthConfig;
  authUrl:string;
  localizationApiUrl: string;
  userInfoUrl:string;
  keycloakApiUrl:string;
}

