import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserNotificationService {
  
  constructor() { }

  orderNotification(title:string = 'new notification', message:string = 'new orders check it up'){
      Notification.requestPermission().then(p =>{if(p === "granted") 
      new Notification(title, {body: message,icon: 'https://ishbek.com/sites/default/files/logo.png'}); })
  }
}
