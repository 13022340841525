import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Observable, timer, Subscription } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  apiUrl = 'api/Notification/';
  selectedlanguage = 'en'
  private hubConnectionBuilder!: HubConnection;
  private notifications: BehaviorSubject<any[]> = new BehaviorSubject(new Array());
  constructor( private _http: HttpClient) { this.selectedlanguage = localStorage.getItem('language') || 'en' }
  get Notifications() { return this.notifications; }

getCategoryCustomer(): Observable<any[]> {
  return this._http.get<any[]>(this.apiUrl + "GetCategoryCustomer").pipe(take(1), pluck('data'));
}


// createNotificationForm = (body:any) => {
//     return this._http.post(`${this.apiUrl}${'CreateForm'}`, body).pipe(take(1))
//   }


  createNotificationForm(body:any){
    return this._http.post(`${environment.companyApi}${'Notification/CreateForm'}`,body).pipe(take(1));
  }
  


  // InitConnection = (identity: any) => {
  //   if (identity.accesstype)
  //     this.hubConnectionBuilder = new HubConnectionBuilder().withUrl(this.envService.SocketUrl + identity.accesstype + 'hub'
  //       , {
  //         skipNegotiation: true, transport: HttpTransportType.WebSockets, headers: {
  //           'Accept': 'application/json',
  //           'rejectUnauthorized': 'false'
  //         }
  //       }).withAutomaticReconnect().build();
  //   this.hubConnectionBuilder.start().then(() => {
  //     this.hubConnectionBuilder.on('NewNotification', (notification: any) => this._handleNewNotification(notification))
  //   })
  // }



  // private _handleNewNotification(notification: any) {
  //   notification = { ...notification, notificationDetails: JSON.parse(notification.notificationDetails) }
  //   console.log(JSON.stringify(notification) + "will be handled");
  //   this.notifications.pipe(take(1)).subscribe((res: any[]) => this.notifications.next([...res, notification]));
  // }
  // handleNotificationClick(nindex: number) {
  //   this.notifications.pipe(take(1)).subscribe((res: any[]) => { res.splice(nindex, 1) });
  // }

  // createNotification = (notification: Notification) => this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'create'}`, notification);

  // updateNotification = (notification: Notification) => this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'update'}`, notification);

  // deleteNotification = (notificationId: any) => this._httpClient.delete(environment.companyApi + this.apiUrl + "Delete?id=" + notificationId);

  // GetNotificationForCompany(): Observable<any[]> {
  //   return this._httpClient
  //     .get(
  //       `${environment.companyApi}${this.apiUrl + 'GetNotificationForCompany'
  //       }`
  //     )
  //     .pipe(take(1), pluck('data'));
  // }

  // GetNotificationForBranch(Branchid: string): Observable<any[]> {
  //   return this._httpClient
  //     .get(
  //       `${environment.companyApi}${this.apiUrl + 'GetNotificationForBranch?Branchid=' + Branchid
  //       }`
  //     )
  //     .pipe(take(1), pluck('data'));
  // }
}






