import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentmenuService {

  apiUrl: string = '/menu'
  constructor(
    private _http: HttpClient) { }

  getMenu(): Observable<menu[]> {
    return this._http.get<menu[]>(`${environment.companyApi}${this.apiUrl + '/getview'}`)
  }
}

interface menu {
  id: string
  name: string
  Isavailable: boolean
  Branches: any[]
  Products: any[]
}
