import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedLanguageService {
  selectedLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('language') || 'en')
  setSelectedLanague = (value: string) => {
    localStorage.setItem('language', value); this.selectedLanguage.next(value);
  }
}
