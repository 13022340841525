import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor( private _httpclient: HttpClient) { }
  async arabic(orderid: string) {
    return new Promise(resolve => { this.getOrderData(orderid).subscribe((result: InvoiceData) => resolve(result)) }).then((response: any) => {
      response = response.data!;

      let baseInvoice = `<!DOCTYPEhtml><html><head><style>body{color:black}.tablePadding{margin-left:25px;margin-right:50px}.noborder th{border:0px !important}}span{font-size:15px;}table,th,td{border:1px solid black;border-collapse:collapse;}div.b{text-align:left;}div.c{text-align:right;}th,td{padding:5px;text-align:center;}tabletrthh1{text-align:right;}tabletrtdh1{text-align:center;}.tl{text-align:left!important}td{text-align: center;}</style></head><body style="font-size:10px;">`
      if (/*invoiceSettings.image*/ response.CompanyImage) baseInvoice += `<center><img src="${environment.filesurl + response.CompanyImage}" width="120px"></center>`
      if (/*invoiceSettings.companyName*/ response.CompanyName) baseInvoice += `<center><h1>${response.CompanyName}</h1></center>`
      if (/*invoiceSettings.branchName*/ response.BranchName) baseInvoice += `<center><h1>${response.BranchName}</h1></center>`
      if (/*invoiceSettings.branchOrderId*/ response.BranchOrderId) baseInvoice += `<center><h1>${response.BranchOrderId}</h1></center>`
      if (/*invoiceSettings.pickupType*/ response.PickupType) baseInvoice += `<center><h1>${response.PickupType}</h1></center>`
      if (/*invoiceSettings.orderTime*/ response.OrderTime) baseInvoice += `<div class="c"><h1> <p style="width:50%;margin-left:30%">${(response.IsScheduled)?`<span style="font-size:10px">(مجدول)&nbsp;&nbsp;&nbsp;</span>`:''}${response.OrderTime}:الوقت</p></h1></div>`
      // if (/*invoiceSettings.orderTime*/ response.IsScheduled !== false)
      if (/*invoiceSettings.orderDate*/ response.OrderDate) baseInvoice += `<div class="c"><h1> <p style="width:50%;margin-left:30%">${response.OrderDate}:التاريخ</p></h1></div>`
      baseInvoice += `  <hr style=" width:70%;"> <center> <table class="tablePadding" style=""><tr><th width="10%" style="padding:25px"><h1>السعر الكلي</h1></th><th width="10%" style="padding:25px"><h1>العدد</h1></th> <th width="10%" style="padding:25px"><h1>سعر الوحدة</h1> </th><th  width="70%" style="padding:25px"><h1>اسم المادة</h1></th></tr>`
      let invoiceTotalPrice = 0;
      let length = response.Products?.length!;
      for (let i = 0; i < length; i++) {
        let product = response.Products[i];

        let productTax = product?.taxpercentage || 0;
        let attributeLength = product.attributes?.length! || 0
        let productName = `<h2>${product.productName?.ar! || product.productName?.en}</h2>`;


        let attributesTotalPrice = 0
        for (let j = 0; j < attributeLength; j++) {
          let attribute = product.attributes![j];
          let attributesObject = JSON.parse(attribute?.attributes);
          //calc price for attributes product
          attributesTotalPrice += +(attributesObject[0]?.priceSubTotal) * (1+ productTax);

          //calc price for sub attributes product
          if (attributesObject[0].subchildren?.length)
            for (let subAttribute = 0; subAttribute < attributesObject[0].subchildren?.length; subAttribute++)
              attributesTotalPrice += +(attributesObject[0]?.subchildren[subAttribute]?.priceSubTotal!) * (1+ productTax)

          productName += `<br><span>${(attribute.attributeName?.ar || attribute.attributeName?.en)}`
          let subattributeLength = JSON.parse(attribute?.attributes).length!
          for (let p = 0; p < subattributeLength!; p++) {
            let subattribute = JSON.parse(attribute?.attributes!)[p];
            productName += `(${(subattribute.name?.ar || subattribute.name?.en)}`;
            let subsubattributeLength = subattribute.attributes?.length!
            for (let x = 0; x < subsubattributeLength!; x++) {
              let subsubattribute = subattribute.attributes![x]; productName += '(' + subsubattribute.attributeName
            }
            subsubattributeLength! || (productName += ')')
          }
          subattributeLength! || (productName += ')</span>');
          productName +=product.productNotes?`<span><h4>${product.productNotes} :الملاحظات</h4></span>` : ''


        }
        invoiceTotalPrice +=this.round((product.totalPrice! + attributesTotalPrice)*(product.count||1))
        baseInvoice += `<tr><td><h2>${this.round((product.count || 1) * (product.totalPrice! + attributesTotalPrice)) || 0}</h2></td><td><h2>${product.count || 1}</h2></td><td><h2>${this.round((product.totalPrice! + attributesTotalPrice||0) )}</h2></td><td style="display: grid;">${productName}</td></tr><tr></tr>`
      }

      baseInvoice += `</table></center>'`
      if (/*Order Notes*/ (response?.OrderNotes)) baseInvoice += `<h2 style="text-align: center;">${response?.OrderNotes!} :الملاحظات </h2>`
      baseInvoice+=`<table class="noborder" style="border:0px !important;width:100%">`;
      if (/*invoiceSettings.deliveryCost*/ +(response.DeliveryCost)) baseInvoice += `<th><h4 style="display: inline;margin-right:5px">JOD ${(this.round(+response.DeliveryCost)+invoiceTotalPrice)} :سعر الكلي</h4></th>`
      if (/*invoiceSettings.deliveryCost*/ +(response.DeliveryCost)) baseInvoice += `<th><h4 style="display: inline;margin-right:5px">JOD ${this.round(response.DeliveryCost)} :سعر التوصيل</h4></th>`
      if (/*invoiceSettings.Totalprice*/ response.TotalPrice) baseInvoice += `<th><h4 style="display: inline;">JOD ${this.round(invoiceTotalPrice)} :المجموع الكلي </h4></th> `
      baseInvoice += '</table>'
      /*if(invoiceSettings.customerName)*/ baseInvoice += `<center><h2>${response.Customer.name} :اسم الزبون</h2> </center>`
      /*if(invoiceSettings.customerPhone)*/ baseInvoice += `<center><h2>${response.Customer.phone} :رقم هاتف لزبون</h2> </center>`
      if (/*invoiceSettings.customerAddress && */response.Customer.address) baseInvoice += `<center><h1>العنوان : ${response.Customer.address}</h1> </center>`
      if (/*invoiceSettings.customerAddress && */response.Customer?.addressdetails!) baseInvoice += `<center><h3> ${response.Customer.addressdetails}</h3> </center>`
      baseInvoice += `<center><h1></h1></center></body></html>`;
      return Promise.resolve(baseInvoice);
    })
  }
  private round = (value: number) => +(Math.round(value * 1000) / 1000)
  private getOrderData = (orderid: string) => this._httpclient.get<InvoiceData>(`${environment.companyApi}order/GetOrderInvoiceData?orderid=${orderid}`).pipe(take(1))
}
interface InvoiceSettings {
  image?: boolean
  companyName?: boolean
  branchName?: boolean
  branchOrderId?: boolean
  pickupType?: boolean
  orderTime?: boolean
  orderDate?: boolean
  deliveryCost?: boolean
  customerName?: boolean
  customerPhone?: boolean
  customerAddress?: boolean
}
interface InvoiceData {
  CompanyImage?: string;
  CompanyName?: string;
  BranchName?: string;
  BranchOrderId?: string; //ref no or branch order id
  PickupType?: string; // if delivery should send the delivery name
  OrderTime?: string;
  OrderDate?: string;
  Products: InvoiceProduct[]
  DeliveryCost?: number;
  TotalPrice?:number;
  Customer: InvoiceCustomer;
}
interface InvoiceProduct {
  productName?: string;
  count: number;
  totalPrice: number;
  taxpercentage: number;
  attributes?: InvoiceAttributeValues[]
}
interface InvoiceAttributeValues {
  attributeName?: string;
  priceSubTotal?: number;
  attributes?: InvoiceAttributeValues[] //for every sub i need theese values
}
interface InvoiceCustomer {
  name: string
  phone: string
  address?: string
}
