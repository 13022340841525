import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2'
@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor(private _router: Router) { }
  success(msg: string, navurl?: string) {
    Swal.fire('Good job!', msg, 'success');
    if (navurl) this._router.navigate([navurl])
  }
  deleteDialog(title:string='Are you sure?',text:string="You won't be able to revert this!",icon:SweetAlertIcon='warning',confirmText:string='Yes, delete it!'): Promise<any> {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#00568f',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText
    })
  }
  AskDialog(title:string='Are you sure?',text:string="You won't be able to revert this!",icon:SweetAlertIcon='warning',confirmText:string='Yes, Cancel it!'): Promise<any> {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#00568f',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText
    })
  }



  SyncDialog(text:any="You won't be able to revert this!",title:string='Are you sure for sync menu to ?',icon:SweetAlertIcon='warning',confirmText:string='Yes, Sync it!'): Promise<any> {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#00568f',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText
    })
  }


  deleteConfirmed() {
    Swal.fire(
      'Deleted!',
      'deleted.',
      'success'
    )
  }
  error(msg: string, navurl?: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: msg,
    }).then(() => { if (navurl) { this._router.navigate([navurl]) } })
  }
  timedAlert(msg: string,position:SweetAlertPosition='bottom-end',time: number = 750,icon:SweetAlertIcon='success') {
    Swal.fire({
      position: position,
      icon: icon,
      title: msg,
      showConfirmButton: false,
      timer: time
    })
  }
  async AskingWithDDL(title:string,msg:string,DDLList:Observable<any>){
    let list:any;
    let swal;
    const _ = await new Promise((res) => { DDLList.subscribe(res_1 => list = res_1); });
    return await (
      swal = Swal.fire({
        title: title,
        text: msg,
        icon: 'question',
        html: `<ddl class="col-12" [id]="'ddl'" [list]="${list}" [hasSearch]="true"
      [searchPlaceHolder]="Search"
      [isrequired]="true">
      </ddl>`,
        showCancelButton: true,
        confirmButtonColor: '#00568f',
        cancelButtonColor: '#d33',
        confirmButtonText: "Confirm"
      }));
  }
}
