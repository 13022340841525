import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatuspossibilityService {

constructor(private _httpClient : HttpClient) { }

getOrderstatuspossibility(branchids: string[]): Observable<any[]> {
  let query='?';
  branchids.forEach(i => {query+='Id='+i+'&'})
  return this._httpClient.get<any[]>(`${environment.companyApi}${'Orderstatuspossibilities/GetBranchOrdersarray' + query}`).pipe(take(1),pluck('data'));
  }
  deleteorderstatuspossibility(deleteid: string) {
    return this._httpClient.delete(`${environment.companyApi}${'Orderstatuspossibilities/Delete'}` + "?Id=" + deleteid);
  }
  updateorderstatuspossibility(updateid: any) {
    return this._httpClient.put(`${environment.companyApi}${'Orderstatuspossibilities/Update'}`, updateid);
  }
  createOrderstatuspossibility(status:any){
    return this._httpClient.post(`${environment.companyApi}${'Orderstatuspossibilities/create'}`,status);
  }
}
