import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CallsubmissionService {

constructor(
  private _httpClient: HttpClient) { }

getcallstatus(): Observable<any> {
  let options: any[] = [];
  return this._httpClient.get<any>(`${environment.companyApi}${'CallSubmission/GetAll'}`).pipe(pluck('data'),take(1))
}

getcallsubstatus(statusid:string): Observable<any[]> {
  return this._httpClient.get<any[]>(`${environment.companyApi}${'CallSubmission/GetAllSub?status='}`+statusid).pipe(take(1),pluck('data'));
}

createcalllog = (log: any)  => this._httpClient.post(`${environment.companyApi}${'CallSubmission/Create'}`,  log  ).pipe(take(1));

}

