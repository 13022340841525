import { Injectable } from '@angular/core';
import { Howl } from 'howler';
import { OrderService } from './order.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  branchsounds: branchsound[] = [];
  isSoundPlaying: boolean = false;
  defaultSound: Howl = new Howl({ format: ['mp3', 'aac'], src: 'https://notificationsounds.com/storage/sounds/file-sounds-1140-just-saying.mp3', html5: true, loop: true, volume: 0.9 });

  constructor(private _orderService: OrderService) { }

  stop = () => {
    this.defaultSound.stop();
    this.branchsounds.map(i => i.sound?.stop())
    this.isSoundPlaying = false;
  }

  play = (branchid: string) => {
    this.stop();
    let sound = (this.branchsounds.filter(i => i.branch == branchid)[0])?.sound;
    !!sound ? sound.play() : this.defaultSound?.play()
    this.isSoundPlaying = true;
  }

  initiateBranchSounds = () => {
    this._orderService.getOrderSound().subscribe((res: any[]) => {
      if (!res || res?.length == 0) return
      res.forEach(audiodetails => {
        this.branchsounds.push({
          branch: audiodetails.branchid,
          sound: new Howl({ format: ['mp3', 'aac', 'wav'], src: environment.filesurl + audiodetails.sound, html5: true, loop: audiodetails.loop, volume: audiodetails.volume })
        })
      });
    })
  }

}
interface branchsound {
  branch: string
  sound: Howl
}
